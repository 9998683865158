<template>
	<el-dialog 
		title="客户选择" 
		top="1vh" width="90%" 
		append-to-body 
		custom-class="el-dialog-s" 
		:visible.sync="is_show_in_page" 
		@close="page_close"
	>
		<div :style="{height:dialog_inner_height}">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加/修改时间" width="145">
					<template slot-scope="scope">
						<div>{{scope.row.create_time_text}}</div>
						<div>{{scope.row.update_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="名称/地址">
					<template slot-scope="scope">
						<div>{{scope.row.customer_info.name}}</div>
						<div>{{scope.row.customer_info.addr}}</div>
					</template>
				</el-table-column>
				<el-table-column label="税号" >
					<template slot-scope="scope">
						<div>{{scope.row.customer_info.tax_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="联系人" prop="link_man" width="">
					<template slot-scope="scope">
						{{scope.row.customer_info.link_tel}}/{{scope.row.customer_info.link_man}}
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="mark" width="400"></el-table-column>

				<!-- 操作行 -->
				<el-table-column label="操作" width="80">
					<template slot-scope="scope">
						<el-button @click="choose(scope.row)" size="mini" type="text">选定</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
			auto_choosed:Boolean,
		},
		data() {
			return {

				is_show_in_page:false,
				dialog_inner_height:'',

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//读取用户组数据
					this.get_page_data()
					
				}else this.is_show_in_page=false;
			}
		},
		methods: {

			//打开页面
			page_show(){
				
				//监听页面高度
				this.dialog_inner_height=parseInt(document.body.clientHeight)-160+'px'
				window.onresize=()=>{
					this.dialog_inner_height=parseInt(document.body.clientHeight)-160+'px'
				}

				//打开弹出层
				this.is_show_in_page=true;
			},

			//选定
			choose(item){

				//通知
				this.$emit('choosed',item);
			},

			//页面关闭时
			page_close(){

				//结束监听
				window.onresize=null;
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'customer_list_by_member',
						status:1,
						is_get_customer_info:1,
						num:100000,
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//预处理
						for(let item of data.list){

							//取出各种info
							item.customer_info=item.customer_info[0];

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//如果存在默认
							if(item.def_customer_of_menber==1 && this.auto_choosed){
								this.$emit('choosed',item)
								return;
							}
						}

						//如果仅有一个结果
						if(data.list.length==1 && this.auto_choosed){
							this.$emit('choosed',data.list[0])
							return;
						}

						//打开页面显示
						this.page_show();
						
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.el-dialog-s{
		z-index: 11;
		height:calc(100% - 2vh) !important;
	}
</style>